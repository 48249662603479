// GRID
// ==============================

$col-count: 12;
$col-spacing: 10px;
$row-spacing: 10px;

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 15px;

    &.row-reverse {
        flex-direction: row-reverse;
    }

    .col {
        flex-grow: 1;
        flex-basis: 100%;
        max-width: 100%;
        padding: $row-spacing/2 $col-spacing/2;

        &.col-reverse {
            flex-direction: column-reverse;
        }
    }

    @for $i from 1 through $col-count {
        .col-xs-#{$i} {
            flex-basis: ($i / $col-count) * 100%;
            max-width: ($i / $col-count) * 100%;
        }
    }
    @for $i from 1 through $col-count {
        .col-xs-offset-#{$i} {
            margin-left: ($i / $col-count) * 100%;
        }
    }

    @include breakpoint-min($bp-small) {
        @for $i from 1 through $col-count {
            .col-sm-#{$i} {
                flex-basis: ($i / $col-count) * 100%;
                max-width: ($i / $col-count) * 100%;
            }
        }
        @for $i from 1 through $col-count {
            .col-sm-offset-#{$i} {
                margin-left: ($i / $col-count) * 100%;
            }
        }
    }

    @include breakpoint-min($bp-medium) {
        @for $i from 1 through $col-count {
            .col-md-#{$i} {
                flex-basis: ($i / $col-count) * 100%;
                max-width: ($i / $col-count) * 100%;
            }
        }
        @for $i from 1 through $col-count {
            .col-md-offset-#{$i} {
                margin-left: ($i / $col-count) * 100%;
            }
        }
    }

    @include breakpoint-min($bp-large) {
        @for $i from 1 through $col-count {
            .col-lg-#{$i} {
                flex-basis: ($i / $col-count) * 100%;
                max-width: ($i / $col-count) * 100%;
            }
        }
        @for $i from 1 through $col-count {
            .col-lg-offset-#{$i} {
                margin-left: ($i / $col-count) * 100%;
            }
        }
    }

    @include breakpoint-min($bp-xlarge) {
        @for $i from 1 through $col-count {
            .col-xlg-#{$i} {
                flex-basis: ($i / $col-count) * 100%;
                max-width: ($i / $col-count) * 100%;
            }
        }
        @for $i from 1 through $col-count {
            .col-xlg-offset-#{$i} {
                margin-left: ($i / $col-count) * 100%;
            }
        }
    }

}
