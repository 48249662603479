// BUTTONS
// ==============================

$button-radius: 4px;
$button-shadow: false;
$button-text-color: $color-white;
$button-colors: (
    'primary' : $brand-color,
    'success' : $color-green-500,
    'info'    : $color-gray-500,
    'warning' : $color-amber-600,
    'danger'  : $color-red-500
);

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
    display: inline-block;
    height: 40px;
    margin: 0 2px 15px;
    padding: 0 20px;
    width: auto;
    background: none;
    overflow: visible;
    appearance: none;
    transition: all .1s ease-out;

    font-size: 14px;
    font-weight: 600;
    color: inherit;
    line-height: 40px;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    border-radius: $button-radius;
    border-style: solid;
    border-width: 1px;

    @if $button-shadow {
        box-shadow: 0 2px 4px rgba($color-gray-500, 0.5);
    }

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: 0.85;
        cursor: pointer;
        transform: scale(1.025);

        @if $button-shadow {
            box-shadow: 0 2px 8px rgba($color-gray-600, 0.5);
        }
    }

    &:active {
        opacity: 1;
        transform: scale(1);
    }

    &:disabled {
        opacity: 0.5;

        &:hover {
            cursor: not-allowed;
        }
    }
}

@each $name, $color in $button-colors {
    .button-#{$name} {
        background-color: $color;
        color: $button-text-color;
        border-color: $color;
    }

    .button-#{$name}-outlined {
        background-color: $color-white;
        color: $color;
        border-color: $color;
    }

    .button-#{$name}-text {
        color: $color;
        border-color: transparent;
    }

}

.button-round {
    border-radius: 24px;
}

.button-large {
    height: 48px;
    line-height: 48px;
    padding: 0 30px;
    font-size: 16px;
}

.button-small {
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    font-size: 12px;
}
