// FOOTER
// ==============================

$footer-text-color: $color-white;
$footer-bg-color: $color-gray-600;
$footer-links-color: $color-gray-400;

.footer,
footer {
    padding: 30px;
    width: 100%;
    background: $footer-bg-color;

    .copyright {
        margin: 0;
        padding-top: 30px;
        font-size: 14px;
        color: $footer-text-color;
        border-top: 1px solid $color-gray-500;

        a {
            color: $footer-links-color;
        }
    }
}

.footer-text {
    p {
        color: $footer-text-color;
    }
}

.footer-links-category {
    color: $footer-text-color;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.footer-links {
    list-style: none;

    li {
        margin-top: 5px;
    }

    a {
        color: $footer-links-color;
    }
}
