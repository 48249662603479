// CONTAINERS
// ==============================

$container-sizes: (
    'small'    : 768px,
    'large'    : 1440px,
);

.container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 0;
}

@each $name, $size in $container-sizes {
    .container-#{$name} {
        max-width: $size;
    }
}
